@use "../../../../../tokens";

.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: radial-gradient(
    141.42% 141.42% at 100% 0%,
    #b833e1 0%,
    #9059ff 37.1%,
    #5b6df8 61.4%,
    #0090ed 100%
  );
}

.backgroundLogoOverlay {
  position: fixed;
  inset-block-end: 0;
  inset-inline-end: 0;
  min-height: 100%;
}

.dialogWrapper {
  max-width: tokens.$content-md;
  display: flex;
  justify-content: center;
}
